import React, { useMemo } from 'react';
import { ReactSVG } from 'react-svg';
import { useIntl } from 'react-intl';
import styles from './PublicSidebarCompanyAndSignatures.module.scss';
import { translate } from '../../../../utility/messageTranslator/translate';
import { User } from '../../../../domain/User';
import { Roles } from '../../../../domain/Role';
import cx from 'classnames';
import { useForm } from 'src/hooks/useForm/useForm';
import TextField from 'src/common/TextField/TextField';
import arrowDown from '../../../../assets/icons/arrow-down.svg';
import { StoreState } from 'src/config/StoreProvider/StoreProvider';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import * as companyService from '../../../../store/company/service';
import { connect } from 'react-redux';
import { uniqBy } from 'lodash';

type Props = {
  onAddCreditsModalOpen: () => void;
  currentUser: User | null;
  companyContainerClass?: string;
  onSelectCompany: (companyId: number) => void;
};

type FormInputs = {
  selectedCompanyId: number;
};

const rolesWithHiddenPurchase = [Roles.USER, Roles.DESIGNER];

const PublicSidebarCompanyAndSignatures = ({
  onAddCreditsModalOpen,
  currentUser,
  companyContainerClass,
  onSelectCompany,
}: Props) => {
  const intl = useIntl();

  const INPUTS = useMemo(() => {
    const addedCompanyOptions = uniqBy(
      currentUser?.userCompanyRoles?.map((companyRole) => {
        return {
          label: companyRole.company.name,
          value: companyRole.company.id,
        };
      }) || [],
      'value',
    );

    const userCompanyOptions =
      currentUser?.companies?.map((company) => {
        return {
          label: company.name,
          value: company.id,
        };
      }) || [];

    return [
      {
        name: 'selectedCompanyId',
        placeholder: translate(intl, `COMPANY_OPTIONS.DEFAULT`),
        type: 'autocomplete',
        options: [...userCompanyOptions, ...addedCompanyOptions],
        value: currentUser?.profile?.selectedCompanyId,
      },
    ];
  }, [
    currentUser?.userCompanyRoles,
    currentUser?.companies,
    currentUser?.profile?.selectedCompanyId,
  ]);

  const handleFilterChange = ({ selectedCompanyId }: FormInputs) => {
    onSelectCompany(selectedCompanyId);
  };

  const { inputs, onSelectChange } = useForm<FormInputs>(
    INPUTS,
    handleFilterChange,
    {
      submitOnChange: true,
    },
  );

  const selectedCompanyBalance = useMemo(() => {
    if (
      currentUser?.profile?.selectedRole === Roles.MANAGER ||
      currentUser?.profile?.selectedRole === Roles.DESIGNER
    ) {
      return currentUser?.userCompanyRoles?.find(
        (userCompanyRole) =>
          userCompanyRole?.companyId ===
          currentUser?.profile?.selectedCompanyId,
      )?.company?.balance;
    }

    return currentUser?.companies?.find(
      (company) => company?.id === currentUser?.profile?.selectedCompanyId,
    )?.balance;
  }, [currentUser]);

  return (
    <>
      <div className={cx(styles.companyContainer, companyContainerClass)}>
        <div className={styles.companySelectionContainer}>
          {inputs.map((input) => (
            <TextField
              key={input.name}
              value={input.value?.toString() ?? ''}
              name={input.name}
              type={input.type}
              options={input.options}
              className={styles.textField}
              onSelectChange={onSelectChange}
              endContainer={<ReactSVG src={arrowDown} />}
              disableSelectTyping
              placeholder={input.placeholder}
              popperClassName={styles.popper}
            />
          ))}
        </div>
      </div>
      {currentUser?.profile?.selectedRole &&
        !rolesWithHiddenPurchase.includes(
          currentUser?.profile?.selectedRole,
        ) && (
          <div className={styles.signaturesContainer}>
            <div className={styles.signatureCountContainer}>
              <span className={styles.count}>
                {intl.formatNumber(selectedCompanyBalance ?? 0)}
              </span>
              <span className={styles.label}>
                {translate(intl, 'PUBLIC_NAVIGATION.SIGNATURES')}
              </span>
            </div>
            <div className={styles.fillSignaturesCta}>
              <p onClick={onAddCreditsModalOpen}>
                {translate(intl, 'PUBLIC_NAVIGATION.ADD_SIGNATURES')}
              </p>
            </div>
          </div>
        )}
    </>
  );
};

const mapStateToProps = (state: StoreState) => ({
  isLoading: state.profile.setSelectedRoleLoading,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onSelectCompany: (companyId: number) =>
    dispatch(companyService.selectCompany(companyId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PublicSidebarCompanyAndSignatures);
