export const FETCH_CERTIFICATE_TEMPLATES_START =
  'FETCH_CERTIFICATE_TEMPLATES_START';
export const FETCH_CERTIFICATE_TEMPLATES_SUCCESS =
  'FETCH_CERTIFICATE_TEMPLATES_SUCCESS';
export const FETCH_CERTIFICATE_TEMPLATES_FAIL =
  'FETCH_CERTIFICATE_TEMPLATES_FAIL';

export const FETCH_DRAFT_CERTIFICATE_TEMPLATES_START =
  'FETCH_DRAFT_CERTIFICATE_TEMPLATES_START';
export const FETCH_DRAFT_CERTIFICATE_TEMPLATES_SUCCESS =
  'FETCH_DRAFT_CERTIFICATE_TEMPLATES_SUCCESS';
export const FETCH_DRAFT_CERTIFICATE_TEMPLATES_FAIL =
  'FETCH_DRAFT_CERTIFICATE_TEMPLATES_FAIL';

export const FETCH_CERTIFICATE_TEMPLATE_START =
  'FETCH_CERTIFICATE_TEMPLATE_START';
export const FETCH_CERTIFICATE_TEMPLATE_SUCCESS =
  'FETCH_CERTIFICATE_TEMPLATE_SUCCESS';
export const FETCH_CERTIFICATE_TEMPLATE_FAIL =
  'FETCH_CERTIFICATE_TEMPLATE_FAIL';

export const FETCH_IS_CERTIFICATE_TEMPLATE_HAS_SIGNED_OR_SENT_CERTIFICATE_START =
  'FETCH_IS_CERTIFICATE_TEMPLATE_HAS_SIGNED_OR_SENT_CERTIFICATE_START';
export const FETCH_IS_CERTIFICATE_TEMPLATE_HAS_SIGNED_OR_SENT_CERTIFICATE_SUCCESS =
  'FETCH_IS_CERTIFICATE_TEMPLATE_HAS_SIGNED_OR_SENT_CERTIFICATE_SUCCESS';
export const FETCH_IS_CERTIFICATE_TEMPLATE_HAS_SIGNED_OR_SENT_CERTIFICATE_FAIL =
  'FETCH_IS_CERTIFICATE_TEMPLATE_HAS_SIGNED_OR_SENT_CERTIFICATE_FAIL';

export const FETCH_SENT_CERTIFICATE_TEMPLATES_START =
  'FETCH_SENT_CERTIFICATE_TEMPLATES_START';
export const FETCH_SENT_CERTIFICATE_TEMPLATES_SUCCESS =
  'FETCH_SENT_CERTIFICATE_TEMPLATES_SUCCESS';
export const FETCH_SENT_CERTIFICATE_TEMPLATES_FAIL =
  'FETCH_SENT_CERTIFICATE_TEMPLATES_FAIL';

export const SAVE_CERTIFICATE_TEMPLATE_START =
  'SAVE_CERTIFICATE_TEMPLATE_START';
export const SAVE_CERTIFICATE_TEMPLATE_SUCCESS =
  'SAVE_CERTIFICATE_TEMPLATE_SUCCESS';
export const SAVE_CERTIFICATE_TEMPLATE_FAIL = 'SAVE_CERTIFICATE_TEMPLATE_FAIL';

export const SAVE_CERTIFICATE_TEMPLATE_THUMBNAIL_START =
  'SAVE_CERTIFICATE_TEMPLATE_THUMBNAIL_START';
export const SAVE_CERTIFICATE_TEMPLATE_THUMBNAIL_SUCCESS =
  'SAVE_CERTIFICATE_TEMPLATE_THUMBNAIL_SUCCESS';
export const SAVE_CERTIFICATE_TEMPLATE_THUMBNAIL_FAIL =
  'SAVE_CERTIFICATE_TEMPLATE_THUMBNAIL_FAIL';

export const CREATE_CERTIFICATE_NUMBERS_START =
  'CREATE_CERTIFICATE_NUMBERS_START';
export const CREATE_CERTIFICATE_NUMBERS_SUCCESS =
  'CREATE_CERTIFICATE_NUMBERS_SUCCESS';
export const CREATE_CERTIFICATE_NUMBERS_FAIL =
  'CREATE_CERTIFICATE_NUMBERS_FAIL';

export const DELETE_CERTIFICATE_TEMPLATE_START =
  'DELETE_CERTIFICATE_TEMPLATE_START';
export const DELETE_CERTIFICATE_TEMPLATE_SUCCESS =
  'DELETE_CERTIFICATE_TEMPLATE_SUCCESS';
export const DELETE_CERTIFICATE_TEMPLATE_FAIL =
  'DELETE_CERTIFICATE_TEMPLATE_FAIL';

export const UPDATE_CERTIFICATE_TEMPLATE_START =
  'UPDATE_CERTIFICATE_TEMPLATE_START';
export const UPDATE_CERTIFICATE_TEMPLATE_SUCCESS =
  'UPDATE_CERTIFICATE_TEMPLATE_SUCCESS';
export const UPDATE_CERTIFICATE_TEMPLATE_FAIL =
  'UPDATE_CERTIFICATE_TEMPLATE_FAIL';

export const CREATE_CERTIFICATE_TEMPLATE_COPY_START =
  'CREATE_CERTIFICATE_TEMPLATE_COPY_START';
export const CREATE_CERTIFICATE_TEMPLATE_COPY_SUCCESS =
  'CREATE_CERTIFICATE_TEMPLATE_COPY_SUCCESS';
export const CREATE_CERTIFICATE_TEMPLATE_COPY_FAIL =
  'CREATE_CERTIFICATE_TEMPLATE_COPY_FAIL';

export const TOGGLE_CERTIFICATE_TEMPLATE_STATUS_START =
  'TOGGLE_CERTIFICATE_TEMPLATE_STATUS_START';
export const TOGGLE_CERTIFICATE_TEMPLATE_STATUS_SUCCESS =
  'TOGGLE_CERTIFICATE_TEMPLATE_STATUS_SUCCESS';
export const TOGGLE_CERTIFICATE_TEMPLATE_STATUS_FAIL =
  'TOGGLE_CERTIFICATE_TEMPLATE_STATUS_FAIL';

export const FETCH_UNSECTIONED_CERTIFICATES_START =
  'FETCH_UNSECTIONED_CERTIFICATES_START';
export const FETCH_UNSECTIONED_CERTIFICATES_SUCCESS =
  'FETCH_UNSECTIONED_CERTIFICATES_SUCCESS';
export const FETCH_UNSECTIONED_CERTIFICATES_FAIL =
  'FETCH_UNSECTIONED_CERTIFICATES_FAIL';

export const FETCH_SECTION_CERTIFICATE_TEMPLATES_START =
  'FETCH_SECTION_CERTIFICATE_TEMPLATES_START';
export const FETCH_SECTION_CERTIFICATE_TEMPLATES_SUCCESS =
  'FETCH_SECTION_CERTIFICATE_TEMPLATES_SUCCESS';
export const FETCH_SECTION_CERTIFICATE_TEMPLATES_FAIL =
  'FETCH_SECTION_CERTIFICATE_TEMPLATES_FAIL';

export const RESET_IS_CERTIFICATE_TEMPLATE_HAS_SIGNED_OR_SENT_CERTIFICATE_STORE =
  'RESET_IS_CERTIFICATE_TEMPLATE_HAS_SIGNED_OR_SENT_CERTIFICATE_STORE';

export const REPLACE_CERTIFICATE_IMAGE_START =
  'REPLACE_CERTIFICATE_IMAGE_START';
export const REPLACE_CERTIFICATE_IMAGE_SUCCESS =
  'REPLACE_CERTIFICATE_IMAGE_SUCCESS';
export const REPLACE_CERTIFICATE_IMAGE_FAIL = 'REPLACE_CERTIFICATE_IMAGE_FAIL';

export const RESET_CERTIFICATE_TEMPLATE_STORE =
  'RESET_CERTIFICATE_TEMPLATE_STORE';
export const RESET_CERTIFICATE_NUMBER_STORE = 'RESET_CERTIFICATE_NUMBER_STORE';
export const RESET_CROP_IMAGE_STORE = 'RESET_CROP_IMAGE_STORE';

export const RESET_CERTIFICATE_TEMPLATE_UPDATE_STORE =
  'RESET_CERTIFICATE_TEMPLATE_UPDATE_STORE';

export const LOGOUT = 'LOGOUT';

export type CertificateTemplateActionTypes =
  | typeof FETCH_CERTIFICATE_TEMPLATES_START
  | typeof FETCH_CERTIFICATE_TEMPLATES_SUCCESS
  | typeof FETCH_CERTIFICATE_TEMPLATES_FAIL
  | typeof FETCH_DRAFT_CERTIFICATE_TEMPLATES_START
  | typeof FETCH_DRAFT_CERTIFICATE_TEMPLATES_SUCCESS
  | typeof FETCH_DRAFT_CERTIFICATE_TEMPLATES_FAIL
  | typeof FETCH_IS_CERTIFICATE_TEMPLATE_HAS_SIGNED_OR_SENT_CERTIFICATE_START
  | typeof FETCH_IS_CERTIFICATE_TEMPLATE_HAS_SIGNED_OR_SENT_CERTIFICATE_SUCCESS
  | typeof FETCH_IS_CERTIFICATE_TEMPLATE_HAS_SIGNED_OR_SENT_CERTIFICATE_FAIL
  | typeof FETCH_CERTIFICATE_TEMPLATE_START
  | typeof FETCH_CERTIFICATE_TEMPLATE_SUCCESS
  | typeof FETCH_CERTIFICATE_TEMPLATE_FAIL
  | typeof FETCH_SENT_CERTIFICATE_TEMPLATES_START
  | typeof FETCH_SENT_CERTIFICATE_TEMPLATES_SUCCESS
  | typeof FETCH_SENT_CERTIFICATE_TEMPLATES_FAIL
  | typeof SAVE_CERTIFICATE_TEMPLATE_START
  | typeof SAVE_CERTIFICATE_TEMPLATE_SUCCESS
  | typeof SAVE_CERTIFICATE_TEMPLATE_FAIL
  | typeof DELETE_CERTIFICATE_TEMPLATE_START
  | typeof DELETE_CERTIFICATE_TEMPLATE_SUCCESS
  | typeof DELETE_CERTIFICATE_TEMPLATE_FAIL
  | typeof SAVE_CERTIFICATE_TEMPLATE_THUMBNAIL_START
  | typeof SAVE_CERTIFICATE_TEMPLATE_THUMBNAIL_SUCCESS
  | typeof SAVE_CERTIFICATE_TEMPLATE_THUMBNAIL_FAIL
  | typeof CREATE_CERTIFICATE_NUMBERS_START
  | typeof CREATE_CERTIFICATE_NUMBERS_SUCCESS
  | typeof CREATE_CERTIFICATE_NUMBERS_FAIL
  | typeof CREATE_CERTIFICATE_TEMPLATE_COPY_START
  | typeof CREATE_CERTIFICATE_TEMPLATE_COPY_SUCCESS
  | typeof CREATE_CERTIFICATE_TEMPLATE_COPY_FAIL
  | typeof TOGGLE_CERTIFICATE_TEMPLATE_STATUS_START
  | typeof TOGGLE_CERTIFICATE_TEMPLATE_STATUS_SUCCESS
  | typeof TOGGLE_CERTIFICATE_TEMPLATE_STATUS_FAIL
  | typeof UPDATE_CERTIFICATE_TEMPLATE_START
  | typeof UPDATE_CERTIFICATE_TEMPLATE_SUCCESS
  | typeof UPDATE_CERTIFICATE_TEMPLATE_FAIL
  | typeof FETCH_UNSECTIONED_CERTIFICATES_START
  | typeof FETCH_UNSECTIONED_CERTIFICATES_SUCCESS
  | typeof FETCH_UNSECTIONED_CERTIFICATES_FAIL
  | typeof FETCH_SECTION_CERTIFICATE_TEMPLATES_START
  | typeof FETCH_SECTION_CERTIFICATE_TEMPLATES_SUCCESS
  | typeof FETCH_SECTION_CERTIFICATE_TEMPLATES_FAIL
  | typeof REPLACE_CERTIFICATE_IMAGE_START
  | typeof REPLACE_CERTIFICATE_IMAGE_SUCCESS
  | typeof REPLACE_CERTIFICATE_IMAGE_FAIL
  | typeof RESET_IS_CERTIFICATE_TEMPLATE_HAS_SIGNED_OR_SENT_CERTIFICATE_STORE
  | typeof RESET_CROP_IMAGE_STORE
  | typeof RESET_CERTIFICATE_TEMPLATE_STORE
  | typeof RESET_CERTIFICATE_NUMBER_STORE
  | typeof RESET_CERTIFICATE_TEMPLATE_UPDATE_STORE
  | typeof LOGOUT;
